.btn{
    font-family: var(--font-default);
    font-size: calc(12rem/16);
    line-height: calc(17/12);
    letter-spacing: calc(1rem/16);
    text-transform: none;
    border-radius: 50vh;
    border: 2px solid;
    padding: calc(12rem/16) calc(15rem/16);
    position: relative;

    @media screen and (min-width: 1200px){
        font-size: calc(14rem/16);
        line-height: calc(21/14);
        padding: calc(11rem/16) calc(28rem/16);
    }
}

.btn.btn-lg{
    padding: calc(14rem/16) calc(31.5rem/16);

    @media screen and (min-width: 1200px){
        padding: calc(14rem/16) calc(30rem/16);
    }
}

.btn.btn-md{
    padding: calc(17rem/16) calc(35rem/16);
}

.btn.btn-sm{
   padding: clamp(0.65rem, 2.5vw, 0.8rem) clamp(0.55rem, 2.5vw, 1.25rem);

    @media screen and (min-width: 768px) and (max-width: 1200px){
        font-size: calc(10rem/16);
        line-height: calc(14/8);
        padding: calc(8rem/16) calc(9rem/16);
    }

    @media screen and (min-width: 1200px) and (max-width: 1400px){
        font-size: calc(10rem/16);
        line-height: calc(14/12);
        padding: calc(12rem/16) calc(15rem/16);
    }

    @media screen and (min-width: 1400px) and (max-width: 1600px){
        font-size: calc(12rem/16);
        line-height: calc(14/12);
        padding: calc(12rem/16) calc(15rem/16);
    }

    @media screen and (min-width: 1600px){
        padding: calc(12rem/16) calc(20rem/16);
        font-size: calc(14rem/16);
    }
}

.btn-primary:hover{
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-light);
}

.btn-secondary{
    background-color: var(--color-secondary);
    color: #fff;
}

.btn-secondary:hover{
    background-color: var(--color-secondary-dark);
    color: #fff;
}
.btn-inline-list .btn:not(:last-child){
    margin-right: calc(5rem/16);
}

.btn.btn-light-grey{
    background-color: var(--color-light-grey);
    color: var(--color-text-default);
}

.btn-no-styling{
    background-color: transparent;
    border: none;
    padding: 0;
    text-align: left;
    font-family: var(--font-default);
    text-transform: none;
}

.btn.btn-link{
    padding: 0;
}

.btn.btn-accordion{
    padding: 0;
    border: none;
    font-family: var(--font-default-bold);
    text-decoration: underline;
    text-transform: none;
}

.btn.btn-ghost{
    position: relative;
    color: #fff;
    border: 1px solid #fff;
    overflow: hidden!important;
    transition: all .3s ease-in-out;
    background: transparent!important;
    z-index:10;
}
@media screen and (min-width: 768px) {
    .btn.btn-ghost:hover{
        border: 1px solid var(--color-default);
        color: var(--color-default)!important;
    }
    .btn.btn-ghost:before {
        content: '';
        width: 0;
        height: 100%;
        display: block;
        background: var(--color-white);
        position: absolute;
        transform: skewX(-20deg);
        left: -10%;
        opacity: 1;
        top: 0;
        z-index: -12;
        transition: all .4s ease;
    }

    .btn.btn-ghost:hover:before{
        opacity:1;
        width: 120%;
    }
}


.btn.btn-active-gradient,
.theme-active .btn.btn-theme-gradient{
   /* background: linear-gradient(29.22deg, #43505A 0%, #E81E39 100%);*/
    background-size: 200% auto;
    transition: 0.5s;
    background-image: linear-gradient(to right, var(--color-third) 7%, var(--color-theme-active) 27.5%, var(--color-theme-active) 63%,var(--color-third) 100%);
    color: #fff;
}
.btn.btn-active-gradient:hover,
.btn.btn-theme-gradient:hover{
    @media screen and (min-width: 768px) {
        background-position: right center;
    }
}

.btn.btn-style-gradient,
.theme-style .btn.btn-theme-gradient{
    background-size: 200% auto;
    transition: 0.5s;
    background-image: linear-gradient(to right, var(--color-third) 7%, var(--color-theme-style) 27.5%, var(--color-theme-style) 63%,var(--color-third) 100%);
    color: #fff;
}

.btn.btn-style-gradient:hover{
    @media screen and (min-width: 768px) {
        background-position: right center;
    }
}

.btn-cart .icon{
    position: absolute;
    left: calc(40rem/16);
    font-size: calc(20rem/16);
}

.btn-cart .icon.icon-close {

    @media screen and (max-width: 767px){
        left: calc(34rem/16);
        font-size:calc(15rem/16);
        top: 49%;
        transform: translateY(-50%);
    }
}

.btn-dynamic-arrow__arrow{
    width: calc(95rem/16);
    display: inline-block;
    border-bottom: 1px solid;
    margin: calc(10rem/16) 0;
    position: relative;
    vertical-align: calc(-5rem/16);
    margin-left: calc(20rem/16);
}

.btn-dynamic-arrow{
    text-align: left;
    width: 100%;
}


.btn-dynamic-arrow .btn-dynamic-arrow__arrow{
    width: calc(100% - calc(25rem/16));
}

.btn-dynamic-arrow__icon{
    position: absolute;
    right: calc(-1rem/16);
    top: 0;
    transform: translateY(-50%);
}

.btn-facebook{
    background-color: var(--color-primary);
    color: #fff;
}

.btn-facebook:hover{
    color: #fff;
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-light);
}

.btn-google{
    background-color: var(--color-primary);
    color: #fff;
}

.btn-google:hover{
    color: #fff;
    background-color: var(--color-primary-light);
    border-color: var(--color-primary-light);
}

.btn-icon .icon{
    font-size: calc(13rem/16);
    vertical-align: calc(-2rem/16);
    margin-right: calc(5rem/16);
    line-height: 0;
}

.bg-white .btn,
.bg-light-grey .btn,
.btn.btn-outline-default{
    position: relative;
    transition: all .3s ease-in-out;
    background: transparent;
    border-radius: 50vh;
    color: var(--color-primary);
    border: 1px var(--color-primary) solid;
}

@media screen and (min-width: 768px) {
    .bg-white .btn:hover,
    .bg-light-grey .btn:hover,
    .btn.btn-outline-default:hover{
        background-color: var(--color-default);
        color: #fff;
    }
}
.btn.btn-outline-default:focus{
    background-color: var(--color-default);
    color: #fff;
}

.btn.btn-outline-white {
    position: relative;
    transition: all .3s ease-in-out;
    background: transparent;
    z-index:10;
    border-radius: 50vh;
    color: #fff;
    border: 1px var(--color-white) solid;
}
@media screen and (min-width: 768px) {
    .btn.btn-outline-white:hover{
        background-color: #fff;
        color: var(--color-primary);
    }
}
.btn.btn-outline-white:focus{
    background-color: #fff;
    color: var(--color-default);
}

/*.btn.btn-outline-summer{*/
/*    position: relative;*/
/*    transition: all .3s ease-in-out;*/
/*    background: transparent;*/
/*    z-index:10;*/
/*    border-radius: 50vh;*/
/*    color: #3A4C6E;;*/
/*}*/
/*@media screen and (min-width: 768px) {*/
/*    .btn.btn-outline-white:hover{*/
/*        background-color: #3A4C6E;;*/
/*        color: var(--color-primary);*/
/*    }*/
/*}*/
/*.btn.btn-outline-white:focus{*/
/*    background-color: #3A4C6E;;*/
/*    color: var(--color-default);*/
/*}*/

/*.btn-summer{*/
/*    background-color: #3A4C6E;*/
/*    color: #fff;*/
/*}*/

.btn-default{
    position: relative;
    transition: all .3s ease-in-out;
    z-index:10;
    border: none;
}

.btn-default:hover{
    background-color: #3b3b3b;
}

.btn-white {
    position: relative;
    transition: all .3s ease-in-out;
    z-index:10;
    border: none;
}

.btn-white:hover{
    background-color: var(--color-default);
    color: #fff;
}


.btn.btn-tooltip{
    background: #4f4f4f;
    color: #fff;
    width: calc(14rem/16);
    height: calc(14rem/16);
    border-radius: 50%;
    font-size: calc(10rem/16);
    text-transform: none;
    pointer-events: auto;
    padding: 0 calc(6rem/16);
}


.btn-back{
    font-family: var(--font-default);
    text-transform: none;
    font-size: calc(11rem/16);
    text-decoration: underline;
}
.btn-back:hover{
    text-decoration: underline;
}


.btn-link-underline {
    font-family: var(--font-title-default);
    font-size: calc(12rem/16);
    text-transform: uppercase;
    text-decoration: underline;
    white-space: pre-line;
    cursor: pointer;
    color: inherit;
}

.btn-link-underline:hover {
    text-decoration: none;
}

.btn-link-underline:focus{
    border: none;
    outline: none;
    text-decoration: none;
}

@media screen and (min-width: 1200px) {
    .btn-link-underline {
        font-size: calc(18rem/16);
    }
}


