

/*.has-navbar-top .main-content:not(.has-transparent-navbar){
    padding-top: calc(60rem/16);

    @media screen and (min-width: 768px){
        padding-top: calc(115rem/16);
    }
}*/

.content-heading-small__title{
    font-size: calc(22rem/16);
    line-height: calc(24/22);
    font-family: var(--font-default-bold);
    text-transform: uppercase;
}
.content-heading__left{
    width: calc(315rem/16);
    display: flex;
    align-items: center;

    @media screen and (min-width: 768px) and (max-width: 991px){
        width: calc(200rem/16);
    }

    @media screen and (min-width: 992px) and (max-width: 1200px){
        width: calc(230rem/16);
    }

    @media screen and (min-width: 1200px) and (max-width: 1400px){
        width: calc(280rem/16);
    }

    @media screen and (min-width: 1400px) and (max-width: 1600px){
        width: calc(300rem/16);
    }

    @media screen and (min-width: 1600px){
        width: calc(320rem/16);
    }
}

.content-heading__left-text{
    width: calc(315rem/16);

    @media screen and (min-width: 768px) and (max-width: 991px){
        width: calc(200rem/16);
    }

    @media screen and (min-width: 992px) and (max-width: 1200px){
        width: calc(230rem/16);
    }

    @media screen and (min-width: 1200px) and (max-width: 1400px){
        width: calc(280rem/16);
    }

    @media screen and (min-width: 1400px) and (max-width: 1600px){
        width: calc(300rem/16);
    }

    @media screen and (min-width: 1600px){
        width: calc(320rem/16);
    }
}

.content-heading__title{
    font-family: var(--font-title-default);
    font-size: calc(18rem/16);
    line-height: calc(24/18);
    text-transform: uppercase;

    @media screen and (min-width: 768px){
        font-size: calc(25rem/16);
        line-height: calc(36/25);
        margin-bottom: calc(24rem/16)
    }
}

.content-heading__toptitle{
    font-size: calc(14rem/16);
    line-height: calc(26/14);
    letter-spacing: calc(1rem/16);
    text-transform: uppercase;

    @media screen and (max-width: 768px){
        font-family: var(--font-default);
        font-size: calc(10rem/16);
        line-height: calc(15/10);
    }
}

.custom-content-spacing-top{
    margin-top: calc(40rem/16);

    @media screen and (min-width: 768px) {
        margin-top: calc(70rem/16) ;
    }

    @media screen and (min-width: 1200px){
        @media screen and (min-width: 768px) {
            margin-top: calc(100rem/16);
        }
    }
}

.custom-content-spacing-bottom{
    margin-bottom: calc(40rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(70rem/16);
    }

    @media screen and (min-width: 1200px){
        @media screen and (min-width: 768px) {
            margin-bottom: calc(100rem/16);
        }
    }
}

.custom-content-spacing{
    margin-bottom: calc(40rem/16);
    margin-top: calc(40rem/16);

    @media screen and (min-width: 768px) {
        margin-bottom: calc(70rem/16);
        margin-top: calc(70rem/16) ;
    }

    @media screen and (min-width: 1200px){
        @media screen and (min-width: 768px) {
            margin-bottom: calc(100rem/16);
            margin-top: calc(100rem/16);
        }
    }
}