.product-detail .breadcrumb {
    @media screen and (min-width: 768px) {
        margin-bottom: calc(10rem / 16);
        max-width: 100%;
    }

    @media screen and (min-width: 1200px) {
        margin-bottom: calc(22rem / 16);
    }
}

.product-detail__scroll-content {
    /*@media screen and (min-width: 768px){
        height: 58vh;
        overflow-y: auto;
        padding: 0 calc(3rem/16);
    }*/
}

.product-detail__scroll-content::-webkit-scrollbar {
    display: none;
}

.product-detail__scroll-content-info {
    @media screen and (min-width: 768px) {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        height: calc(45rem / 16);
        font-family: var(--font-default-bold);
        font-size: calc(10rem / 16);
        letter-spacing: calc(1.2rem / 16);
        text-transform: uppercase;
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.7) 25%, rgba(255, 255, 255, 0.9) 64%, rgba(255, 255, 255, 0.9) 80%, rgba(255, 255, 255, 1) 100%);
        box-shadow: 0 -5px 40px 7px rgb(255 255 255 / 58%);
        opacity: 1;
        transition: opacity 350ms ease;
    }

    @media screen and (max-width: 767px) {
        display: none;
    }
}

.product-detail__scroll-content-info.hide {
    @media screen and (min-width: 768px) {
        opacity: 0;
    }
}

.product-detail__title {
    text-transform: none;
    letter-spacing: 0;
    margin-bottom: calc(9rem / 16);
    font-size: clamp(calc(20rem / 16), 2.5vw, calc(30rem / 16));
    line-height: calc(35 / 30);

    @media screen and (max-width: 767px) {
        font-size: calc(16rem / 16);
        line-height: calc(20 / 16);
        margin-bottom: calc(5rem / 16);
    }
}

.product-detail__toptitle {
    font-size: calc(12rem / 16);
    line-height: calc(20 / 12);
    letter-spacing: calc(1rem / 16);
    text-transform: uppercase;
    font-family: var(--font-title-bold);
    margin-bottom: calc(5rem / 16);
    margin-top: calc(20rem / 16);

    @media screen and (max-width: 768px) {
        display: none;
    }
}

.product-detail__price {
    font-family: var(--font-title-default) !important;
    font-size: calc(12rem / 16);
    line-height: calc(12 / 18);
    margin-right: calc(8rem / 16);

    @media screen and (min-width: 768px) {
        font-size: calc(14rem / 16);
        line-height: calc(14 / 18);
        margin-right: calc(8rem / 16);
    }
}

.product-detail__wysiwyg {
    @media screen and (min-width: 768px) {
        display: flex;
        flex-direction: column;
    }
}

.product-detail__article-number {
    font-size: calc(12rem / 16);
    line-height: calc(26 / 12);

    @media screen and (max-width: 768px) {
        font-size: calc(11rem / 16);
        line-height: calc(24 / 11);
    }
}

.product-detail__info {
    @media screen and (max-width: 768px) {
        margin-top: calc(15rem / 16)
    }
}

.product-detail__price-info {
    line-height: calc(18 / 14);

    @media screen and (max-width: 767px) {
        font-size: calc(13rem / 16);
        line-height: calc(24 / 13);
    }
}

/* strike price */
/*.product-detail__discountprice {*/
/*    font-size: calc(14rem/16);*/
/*    line-height: calc(26/14);*/
/*    font-family: var(--font-title-default);*/
/*}*/
/*.product-detail__strikeprice {*/
/*    font-size: calc(14rem/16);*/
/*    line-height: calc(26/14);*/
/*    font-family: var(--font-default-bold);*/
/*}*/
/*.product-detail__discount {*/
/*    font-size: calc(14rem/16);*/
/*    line-height: calc(26/14);*/
/*    color: var(--color-secondary);*/
/*    font-family: var(--font-default-bold);*/
/*}*/
/*.product-detail__discountprice-info {*/
/*    font-size: calc(14rem/16);*/
/*    line-height: calc(26/14);*/
/*    font-family: var(--font-default-bold);*/
/*}*/

/*TODO price*/
.product-detail-sticky-bar__price {
    font-family: var(--font-title-default);
    font-size: calc(14rem / 16);
    line-height: calc(20 / 13);
    margin-right: calc(8rem / 16);
}

.product-detail-sticky-bar__strikeprice {
    font-size: calc(9rem / 16);
    line-height: calc(11 / 9);
}

.product-detail-sticky-bar__discount {
    font-size: calc(9rem / 16);
    line-height: calc(11 / 9);
    margin-left: calc(9rem / 16);
}

/*@media(max-width: 767px){*/
/*    .product-detail__discountprice {*/
/*        font-size: calc(14rem/16);*/
/*    }*/
/*    .product-detail__discountprice-info {*/
/*        font-size: calc(12rem/16);*/
/*        line-height: calc(24/12);*/
/*        color: #4B4B4B;*/
/*        margin-left: calc(9rem/16);*/
/*    }*/
/*}*/

.product-detail__price-block {
    margin: calc(6rem / 16) 0 calc(15rem / 16);

    @media screen and (min-width: 768px) {
        margin: clamp(calc(6rem / 16), 2.5vw, calc(8rem / 16)) 0 clamp(calc(23rem / 16), 2.5vw, calc(29rem / 16));
    }
}

.product-detail__block-titles {
    font-size: calc(14rem / 16);
    line-height: calc(18 / 14);
    text-transform: none;
    font-family: var(--font-default);
    margin-bottom: calc(11rem / 16);

    @media screen and (max-width: 768px) {
        font-size: calc(12rem / 16);
        line-height: calc(16 / 12);
    }
}

.product-detail__color-block .product-color__slide-btn {
    width: calc(60rem / 16);
    height: calc(60rem / 16);
    padding: calc(1rem / 16);

    @media screen and (max-width: 768px) {
        width: calc(47rem / 16);
        height: calc(50rem / 16);
    }
}

.product-detail__color-block .product-color__slide-btn.is-next {
    right: 0;
    top: 0;
}

.product-detail__color-block .product-color__slide-btn.is-prev {
    left: 0;
    top: 0;
}

.product-detail__color-block .product-colors__list {
    width: calc(100% - 60px);
    margin: 0;

    @media screen and (max-width: 768px) {
        width: calc(100% - 40px);
    }
}

.product-detail__colors .product-color__slide-btn.hide {
    opacity: 0;
}

.product-detail__colors .product-color__slide-btn.hide:hover {
    background-color: #f0f0f0;
    color: var(--color-default);
}

.product-detail__colors {
    position: relative;
    @media screen and (min-width: 768px) and (max-width: 1600px) {
        display: flex;
    }
}


.btn.product-detail__size-table {
    padding: 0;
    font-size: calc(14rem / 16);
    letter-spacing: 0;
}

.product-detail__size-table .icon {
    font-size: calc(17rem / 16);
    margin-right: calc(5rem / 16);
    color: var(--color-default);

    @media screen and (min-width: 768px) {
        font-size: calc(20rem / 16);
    }
}

.product-detail__size-table-text {
    text-decoration: underline;
    color: var(--color-default);
    font-family: var(--font-default-bold);

    @media screen and (max-width: 767px) {
        font-size: calc(12rem / 16);
    }
}

.product-detail__form-container {
    @media screen and (max-width: 1200px) {
        margin-bottom: calc(82rem / 16);
    }
}

.product-detail__buy-local {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;

    @media screen and (max-width: 1200px) {
        width: 100%
    }
    @media screen and (min-width: 1201px) {
        position: absolute;
        bottom: 0;
    }
}

/*.product-detail__buy-local .btn {*/
/*    @media screen (min-width: 1300px) and (max-width: 1201px){*/
/*        margin-left: calc(10rem / 16);*/
/*    }*/
/*}*/
/* ------------------------ BUY LOCAL BUTTON POSITIONS  ------------------------ */
@media screen and (min-width: 1300px) {
    /* backInStock*/
    /* "Benachrichtige mich" btn aktiv */
    .product-detail__buy-local--soldout {
        left: calc(307rem / 16);
    }
    /*Benachrichtigung aktiv*/
    .product-detail__buy-local--soldout-active {
        left: calc(329rem / 16);
    }
}
@media screen and (max-width: 1299px){
    .product-detail__buy-local.product-detail__buy-local--soldout,
    .product-detail__buy-local.product-detail__buy-local--soldout-active{
        position: relative;
        bottom: auto;
        width: 100%
    }
    .product-detail__buy-local--soldout #outtra-base-container ,
    .product-detail__buy-local--soldout-active #outtra-base-container {
        width: 100%;
    }
    .product-detail__buy-local--soldout #outtra-modal-trigger-button ,
    .product-detail__buy-local--soldout-active #outtra-modal-trigger-button {
        height: calc(45rem / 16);
        margin-left: 0;
        width: 100%;
        font-size: calc(12rem / 16);
        line-height: calc(21 / 12);
        letter-spacing: calc(1rem / 16);
    }
    .product-detail__buttons .btn.btn-back-in-stock,
    .product-detail__buttons .btn.btn-back-in-stock-active{
        width: 100%;
        margin-bottom: calc(6rem / 16);
        letter-spacing: calc(1rem / 16);
        padding: calc(12rem / 16) calc(10rem / 16) calc(14rem / 16) calc(10rem / 16);
        border: var(--color-primary) 1px solid;
    }

}
@media screen and (min-width: 1201px) {

    /* backInStock*/
    html[lang="en"] .product-detail__buy-local--soldout {
        /* "Notify me" btn aktiv */
        left: calc(223rem / 16);
    }
    html[lang="it"] .product-detail__buy-local--soldout {
        /* "Avvisami" btn aktiv */
        left: calc(217rem / 16);
    }

    /*notification activ*/
    html[lang="en"] .product-detail__buy-local--soldout-active {
        left: calc(293rem / 16);
    }
    /*Notifica attiva*/
    html[lang="it"] .product-detail__buy-local--soldout-active {
        left: calc(261rem / 16);
    }
    /*----------*/
    .product-detail__buy-local--available {
        /*"In den Warenkorb" btn aktiv */
        left: calc(256rem / 16);
    }
    html[lang="en"] .product-detail__buy-local--available {
        /* "Add to cart" btn aktiv */
        left: calc(212rem / 16);
    }
    html[lang="it"] .product-detail__buy-local--available {
        /* "Metti nel carrello" btn aktiv */
        left: calc(253rem / 16);
    }
    /*----------*/
    .product-detail__buy-local--soldout-online {
        /* "Online nicht erhältlich" btn aktiv */
        left: calc(292rem / 16);
    }
    html[lang="en"] .product-detail__buy-local--soldout-online {
        /* "Not available online" btn aktiv */
        left: calc(273rem / 16);
    }
    html[lang="it"] .product-detail__buy-local--soldout-online {
        /* "Non disponibile online" btn aktiv */
        left: calc(273rem / 16);
    }
}
/* ------------------------ ------------------------- ------------------------ */
@media (max-width: 1200px) {
    #outtra-base-container {
        width: 100%;
    }
}
.product-detail__buy-local #outtra-modal-trigger-button {
    font-size: calc(14rem / 16);
    font-family: var(--font-default);
    cursor: pointer;
    outline: none;
    height: calc(51rem / 16);
    width: calc(171rem / 16);
    border-radius: 50vh;
    margin-left: calc(21rem / 16);
    text-transform: none;

    /*@media screen (max-width: 1300px) and (min-width: 1201px){*/
    /*    margin-left: calc(10rem / 16);*/
    /*}*/
    @media screen and (max-width: 1200px) {
        height: calc(45rem / 16);
        margin-left: 0;
        width: 100%;
        font-size: calc(12rem / 16);
        line-height: calc(21 / 12);
        letter-spacing: calc(1rem / 16);
    }
}

.product-detail__buy-local #outtra-modal-trigger-button:focus {
    outline: none;
}

/*.sold-out .product-detail__buy-local #outtra-modal-trigger-button {*/
/*    @media screen and (max-width: 1400px) {*/
/*        width: 100%;*/
/*    }*/
/*}*/

.product-detail__usps {
    margin-top: calc(46rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(37rem / 16);
    }
}

.product-detail__usp {
    font-size: calc(14rem / 16);
    line-height: calc(18 / 14);
    position: relative;
    padding-left: calc(24rem / 16);
}

.product-detail__usp + .product-detail__usp {
    margin-top: calc(7rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(12rem / 16);
    }
}

.product-detail__usp-icon {
    margin-right: calc(8rem / 16);
    position: absolute;
    left: 0;
    top: calc(6rem / 16);
    font-size: calc(11rem / 16);
    @media screen and (min-width: 768px) {
        top: calc(4rem / 16);
        font-size: calc(13rem / 16);
    }
}

.product-detail__accordion {
    margin-top: calc(46rem / 16);

    @media screen and (min-width: 768px) {
        margin-top: calc(57rem / 16);
    }
}

.product-detail__accordion .product-info {
    @media screen and (min-width: 768px) {
        padding-right: calc(100rem / 16);
    }
    @media screen and (min-width: 1200px) {
        padding-right: calc(140rem / 16);
    }
}

.logo-text-list__img {
    max-height: calc(40rem / 16);
    margin-top: calc(5rem / 16);
}

.logo-text-list li + li {
    margin-top: calc(25rem / 16);
}

.product-detail-img {
    background-color: var(--color-light-grey);
    display: block;

    @media screen and (max-width: 767px) {
        pointer-events: none;
    }
}

div.product-detail-img-slider > div + div {
    display: block;
}

.product-detail-img-slider .slick-dots {
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(-35rem / 16);
    padding: calc(10rem / 16) calc(10rem / 16) 0;
}

.product-detail-img-slider .slick-dots button {
    background-color: #c1c1c1;
}

.product-detail-img-slider .slick-dots > li {
    padding: 0;
    max-width: 100%;
    flex-grow: 1;
    flex-basis: 0;
}

.product-detail-img-slider .slick-dots > li:not(:last-child) {
    margin-right: calc(5rem / 16);
}

.product-detail-img-slider .col-md-6 {
    @media screen and (max-width: 767px) {
        padding: 0;
    }
}

.product-detail-img-slider__nav {
    display: grid;

    @media screen and (max-width: 768px) {
        display: none;
    };
}

.product-detail-img-slider__nav .slick-current .product-detail-img,
.product-detail-img-slider__nav .product-detail-img:hover {
    position: relative;
    cursor: pointer;
}

.product-detail-img-slider__nav .slick-current .product-detail-img:before,
.product-detail-img-slider__nav .product-detail-img:hover::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: solid 2px var(--color-primary);
    width: 100%;
    height: 100%;
    z-index: 999;
}


.product-detail .btn-lg {
    @media screen and (max-width: 767px) {
        height: calc(60rem / 16);
    }
}

.product-detail.sold-out .btn-cart {
    display: none;
}

.product-detail .btn-sold-out {
    display: none;
}

.product-detail.sold-out .btn-sold-out {
    display: block;
    pointer-events: none;
}

.product-detail__loading .loading-spinner {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(100rem / 16);

    @media screen and (min-width: 768px) {
        top: calc(200rem / 16);
    }
}

.product-detail-sticky-bar,
.is-bottom .product-detail-sticky-bar.is-affix {
    position: fixed;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: auto !important;
    z-index: 100;

    background-color: var(--color-white);
    box-shadow: 0 calc(-40rem / 16) calc(40rem / 16) calc(-40rem / 16) rgb(0, 0, 0, 0.1);
    width: 100% !important;
    visibility: hidden;
    height: 0;
    transform: translateY(100%) !important;

    transition: transform 300ms ease, visibility 300ms ease, height 300ms ease;
    transition-delay: 200ms;
}

.product-detail-sticky-bar.is-affix {
    visibility: visible;
    transform: translateY(0%) !important;
    height: calc(100rem / 16);
    padding: calc(10rem / 16) 0;
    transition-delay: 200ms;

    @media screen and (max-width: 768px) {
        height: auto;
        padding: 0;
    }
}

.product-detail-sticky-bar__title {
    font-size: calc(18rem / 16);
    font-family: var(--font-title-bold);
    line-height: calc(30 / 18);
    margin-bottom: calc(2rem / 16);
}

.product-detail-sticky-bar__subtitle {
    text-transform: none;
    font-family: var(--font-default);
}

.product-detail-sticky-bar__btn {
    width: calc(70rem / 16);
    height: calc(70rem / 16);
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(22rem / 16);
    margin-left: calc(20rem / 16);
}

/*.product-detail-sticky-bar__price {*/
/*    font-size: calc(16rem / 16);*/
/*    line-height: calc(26 / 16);*/
/*    margin-right: calc(8rem / 16);*/
/*}*/

.product-detail-sticky-bar__info {
    font-size: calc(12rem / 16);
    line-height: calc(18 / 12);
}

.product-detail-sticky-bar__img {
    margin-right: calc(15rem / 16);
    margin-left: calc(10rem / 16);
}

.product-detail-sticky-bar__placeholder {
    min-height: 0 !important;
}

.product-detail__buttons {
    @media screen and (min-width: 1200px) {
        display: flex;
        flex-direction: row;
    }
}

.product-detail__buttons .btn {
    @media screen and (max-width: 1200px) {
        width: 100%;
        margin-bottom: calc(6rem / 16);
        letter-spacing: calc(1rem / 16);
        padding: calc(12rem / 16) calc(10rem / 16) calc(14rem / 16) calc(10rem / 16);
        border: var(--color-primary) 1px solid;
    }
    @media screen and (min-width: 1200px) {
        padding: calc(12rem / 16) calc(61rem / 16) calc(14rem / 16) calc(61rem / 16);
        border: var(--color-primary) 1px solid;
    }
}

.product-detail-img__slick-button {
    border-radius: 50%;
    height: calc(45rem / 16);
    width: calc(45rem / 16);
    padding: calc(15rem / 16);
    border: solid 1px var(--color-grey);
    background-color: var(--color-white);
    font-size: calc(6rem / 16);
    margin: calc(20rem / 16) 0;
    justify-self: center;
}

.product-detail-img__slick-button:hover {
    border: 1px solid var(--color-primary);
    cursor: pointer;
}

.product-detail-img__slick-button .icon {
    color: var(--color-primary);
    font-size: calc(6rem / 16);
}

.btn-cart__sticky-bar {
    @media screen and (max-width: 768px) {
        height: 100%;
        width: 100%;
        border-radius: 0;
        border: none;
        font-size: calc(12rem / 16);
        line-height: calc(21 / 12);
        letter-spacing: calc(1rem / 16);

    }
}

.product-detail-sticky-bar__inner {
    @media screen and (min-width: 768px) {
        padding: 0 calc(18rem / 16);
    }
    @media screen and (max-width: 768px) {
        display: none;
    }
}

.product-detail-img-info {
    display: flex;
    align-items: center;
}

.product-detail-img-info__icon {
    display: flex;
    background-color: var(--color-default);
    color: var(--color-white);
    border-radius: 50%;
    width: 1.1rem;
    height: 1rem;
    justify-content: center;
    align-items: center;
    margin-right: 4px;
    font-size: 0.6rem;

    @media screen and (min-width: 768px) {
        width: 1rem;
    }
}

.product-detail-img-info__text {
    width: fit-content;
}

.product-detail__not-in-stock{
    display: flex;
    align-items: center;
    color: var(--color-secondary);
    font-family: var(--font-default);
    margin-top: calc(14rem/16);
    margin-bottom: calc(36rem / 16);
}
.product-detail-not-in-stock__icon {
    background-color: var(--color-secondary);
    margin-right: calc(10rem / 16);
}
.product-detail-not-in-stock__text{
    font-size: calc(14rem / 16);
    line-height: calc(18 / 14);
}
